@import "modules/responsive-type.scss";

$primary: rgb(11, 65, 86);/* MAIN COLOR */
$secondary: rgb(216, 68, 68); /* SECONDARY COLOR */
$blk: rgb(42, 38, 46); /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: rgb(255, 255, 255);
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #333;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Gugi|Holtwood+One+SC|Raleway');
// font-family: 'Gugi', cursive;
// font-family: 'Holtwood One SC', serif;
// font-family: 'Raleway', sans-serif;


html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
}

h1 {
    font-family: 'Holtwood One SC', serif;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
    font-family: 'Holtwood One SC', serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);

  @media (max-width: 991px) {
    font-size:  25px;
  }
}
h3 { 
    font-family: 'Holtwood One SC', serif;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    color: $primary;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: $wht;
    background: radial-gradient(circle farthest-corner at left center, rgba($wht, 0.5) 50%, darken($wht, 15%) 100%);
    background: -webkit-radial-gradient(circle farthest-corner at left center, rgba($wht, 0.5) 50%, darken($wht, 15%) 100%);
    background: -moz-radial-gradient(circle farthest-corner at left center, rgba($wht, 0.5) 50%, darken($wht, 15%) 100%);
    box-shadow: 0px 0px 20px 0px $blk;
    border-radius: 0;

    .collapse.in {
        background-color: rgba($wht,0.7);
    }

    .navbar-nav {
        >li>a {
             font-family: 'Holtwood One SC', serif;
            text-align: center;
            margin-top: 20px;
            display: flex;
            align-items: center;
            color: $primary;
            border-radius: 0;
            transition: all 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    @media (max-width: 815px) and (orientation: landscape) {
        margin: 2em auto;
    }
    .close {
        display: none;
    }
    .modal-content {
        color: $blk
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 0.5em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/




footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $wht;
    font-size: 15px;

    a {
        color: $wht;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 180px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 150px;
        padding: 0.1em;
    }
    .navbar-toggle {
        margin-top: 18px;
    }
}

.top-pad {
    padding: 3em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 767px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
     font-family: 'Raleway', sans-serif;
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}


.intro{
    background:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/intro.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: 50% 20%;
    padding:400px 0px;

    @media (max-width: 991px){
        padding: 300px 0px;
        background-position: 50% 50%;
    }

    @media (max-width: 768px){
        padding: 200px 0px;
        background-position: 50% 50%;
    }

    @media (max-width: 568px){
        padding:  150px 0px;
        background-position: 50% 50%;
    }
    
    @media (max-width: 414px){
        padding: 150px 0px;
        background-position: 50% 50%;
    }

    @media (max-width: 320px){
        padding: 100px 0px;
        background-position: 42% 50%;
    }


    h1,p{
        color: #fff;
    }
}


.form{
    background:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/form-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: 50% 20%;
    padding:400px 0px;

    @media (max-width: 768px){
    padding: 200px 0px;
    background-position: 50% 50%;
    }

    @media (max-width: 568px){
        padding:  150px 0px;
        background-position: 50% 50%;
    }
    
    @media (max-width: 414px){
        padding: 150px 0px;
        background-position: 33% 50%;
    }

    @media (max-width: 320px){
        padding: 100px 0px;
        background-position: 34% 50%;
    }

    .bgthis{
        background-color: rgba(221, 123, 2, 0.8);
        border-radius: $border-radius;

        p{
            color: #fff;
            padding: 25px 10px;

        }
    }
}

.divider{

    background-color: $secondary ;

    .img1{
        background:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/img1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        // background-attachment: fixed;
        background-position: 50% 50%;
        padding: 200px 0px;

        @media (max-width: 568px){
        padding:  150px 0px;
        background-position: 50% 50%;
        }

        @media (max-width: 414px){
        padding: 150px 0px;
        background-position: 50% 50%;
        }

        @media (max-width: 320px){
        padding: 100px 0px;
        background-position: 50% 50%;
        }
    }

    .img2{
        background:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/img2.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        // background-attachment: fixed;
        background-position: 50% 50%;
        padding: 200px 0px;
        
        @media (max-width: 568px){
        padding:  150px 0px;
        background-position: 50% 50%;
        }

        @media (max-width: 414px){
        padding: 150px 0px;
        background-position: 50% 50%;
        }

        @media (max-width: 320px){
        padding: 100px 0px;
        background-position: 50% 50%;
        }
    }

 h1,p{
        color: #fff;
    }
}


.padLR {
    padding-left: 5em;
    padding-right: 5em;

    // iPhone X
    @media (max-width: 380px) and (orientation: portrait) {
        padding-left: 1em;
        padding-right: 1em;
    }    
}


.marR {
    margin-right: 20px;
}


.outro{
    background:linear-gradient(77deg,
        rgba($blk, 0.25) 50%, 
        rgba($primary, 0.5) 50.1%
        ), url(../img/outro.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: top center;
    padding:350px 0px;

    @media (max-width: 991px){
        padding: 150px 0px;
        background-position: 50% 50%;
    }

    @media (max-width: 768px){
        padding: 200px 0px;
        background-position: 50% 50%;
    }

    @media (max-width: 568px){
        padding:  150px 0px;
        background-position: 50% 50%;
    }
    
    @media (max-width: 414px){
        padding: 150px 0px;
        background-position: 50% 50%;
    }

    @media (max-width: 320px){
        padding: 100px 0px;
        background-position: 50% 50%;
    }


    h1,p{
        color: #fff;
    }
}


.btn {
  font-family: 'Raleway', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 4px;
  color: $wht;
  background-color: rgba($secondary,0.5);
  border: 3px solid $wht;
  padding: 1.5% 10%;
  margin-top: 1em;
  transition: all 1s;

    @media (max-width: 991px) {
        font-size: 20px;
    }

    &:hover {
        background-color: rgba($primary,1);
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}












.noMar {
    margin-left: 0;
    margin-right: 0;
}



video { 
    width: 100%;
    height: 100%;
    display: block; /* THIS NEEDS TO STAY BLOCK OR WILL HAVE MAGICAL PADDING */
    object-fit: cover;
    

}


.videoBox {
    position: relative;
    min-width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: -10em;
    padding: 0;

        @media (max-width: 991px) {
            height: 170%;
        }

        @media (max-width: 740px) and (orientation: landscape) {
            height: 75%;
        }

    // iPhone X
        @media (max-width: 420px) and (orientation: portrait) {
            height: 75%;
        }

    video {
        @media (max-width: 420px) and (orientation: portrait) {
            position: relative;
            right: 30vw;
        }
        // iPhone X
        @media (max-width: 380px) and (min-height: 800px) and (orientation: portrait) {
            // position: static;
            // right: 0vw;
            // left: 0;
        }
    }

    & + * {
        position: relative;
        margin-top: -1px;
    }
}

.video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background:linear-gradient(
        rgba($blk,0.6),
        rgba($wht,0.5),
        rgba($wht,1)
        );
    min-height: 100%;
    transition: all 1s ease;
    -webkit-transform: translate3d(0,0,0); /* MAY NEED TO KEEP TRANSITION SMOOTH */


    h1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-transform: uppercase;
        text-align: center;
        color: $primary;
        font-size: 70px;
        // text-shadow: 0px 0px 15px $blk;
        transition: all 1s ease;
        
        @media (max-width: 991px) {
            font-size: 30px;
        }
        // iPhone X
        @media (max-width: 380px) and (orientation: portrait) {
            font-size: 40px;
            top: 60%;
        }
    }
}




#whtVid {
    // border-bottom: 10px solid black;
}


#blkVid {
    background-color: $blk;
    color: $wht;
}








.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        display: block;  /* may need to be inline-block */
        position: relative;
    }

        @media (max-width: 767px) {
            position: static;
        }

        .center {
            position: absolute;
            top: 50%;
            // left: 50%;
            // margin-right: -50%;
            transform: translateY(-50%);
            // text-align: center;

            @media (max-width: 767px) {
                position: static;
                transform: translate(0,0);
            }
        }
       
}


.bgOver {
    position: relative;
    z-index: 500;
    color: $wht;
    // iPhone X
    @media (max-width: 991px) {
        color: $primary;
    }    


    // Depending on the text amount, font and font-size you will need to edit 'height' and 'top'
    &:before {
        content: '';
        background-color: $blk;
        border: 5px solid $wht;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 800px;
        top: -190px;
    
        // iPhone X
        @media (max-width: 991px) {
            display: none;
        }    
    }


    h2 {
        padding:0em 0.5em 0em 1em;
        @media (max-width: 991px) {
            font-size: 30px;
        }
    }
}


@mixin hr {
    border: 5px solid $primary;
    border-radius: 10px;
    width: 50%;
    position: absolute;
    left: 25%;
}

hr.top {
    @include hr;
    top: 40%;
    // margin: 0 auto;

    @media (max-width: 991px) {
        top: 43%;
    }
        @media (max-width: 740px) and (orientation: landscape) {
            top: 40%;
        }
    // iPhone X
    @media (max-width: 380px) and (orientation: portrait) {
        top: 45%;
    }
}

hr.bottom {
    @include hr;
    top: 60%;

    @media (max-width: 991px) {
        top: 55%;
    }

        @media (max-width: 740px) and (orientation: landscape) {
            top: 57%;
        }    

    // iPhone X
    @media (max-width: 380px) and (orientation: portrait) {
        top: 75%;
    }    
}


.pop {
    color: $secondary;
}







.noMarLR {
    margin-right: 0;
    margin-left: 0;
}